<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-tree-table
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="gridClass.columns"
          :data="gridClass.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm">
          <c-card title="설비유형 상세정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addClass" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="equipClassData"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveClass"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  label="설비유형 명칭"
                  name="equipmentTypeName"
                  v-model="equipClassData.equipmentTypeName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  :disabled="updateMode"
                  label="설비유형 코드"
                  name="equipmentTypeCd"
                  v-model="equipClassData.equipmentTypeCd">
                </c-text>
              </div>
              <div class="col-6">
                <c-equip-class
                  :editable="editable && dataeditable"
                  label="상위 설비유형"
                  name="upEquipmentTypeCd"
                  v-model="equipClassData.upEquipmentTypeCd">
                </c-equip-class>
              </div>
              <div class="col-6">
                <c-plant 
                  required
                  type="edit" 
                  :disabled="updateMode" 
                  :editable="editable && dataeditable"
                  name="plantCd" 
                  v-model="equipClassData.plantCd" 
                />
              </div>
              <!-- <div class="col-4">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="일상점검 대상"
                  name="dailyFlag"
                  v-model="equipClassData.dailyFlag"
                />
              </div>
              <div class="col-4">
                <c-radio
                  :editable="editable && dataeditable"
                  :comboItems="hazardMachineItems"
                  label="유해위험기계기구 여부"
                  name="hazardMachineFlag"
                  v-model="equipClassData.hazardMachineFlag">
                </c-radio>
              </div> -->
              <div class="col-4">
                <c-radio
                  :editable="editable && dataeditable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="equipClassData.useFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :maxlength="200"
                  :editable="editable && dataeditable"
                  :rows="5"
                  label="비고"
                  name="remarks"
                  v-model="equipClassData.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-class',
  data() {
    return {
      gridClass: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            // style: 'width:300px',
            sortable: false,
          },
          // {
          //   name: 'dailyFlagName',
          //   field: 'dailyFlagName',
          //   label: '일상점검대상',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
          // {
          //   name: 'hazardMachineFlagNm',
          //   field: 'hazardMachineFlagNm',
          //   label: '유해위험기계기구 여부',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          // },
          {
            name: 'useFlagNm',
            field: 'useFlagNm',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '362px'
      },
      searchParam: {
        plantCd: null,
        hazardMachineFlag: null,
        useFlag: 'Y',
      },
      equipClassData: {
        plantCd: null,
        equipmentTypeName: '',
        equipmentTypeCd: '',
        upEquipmentTypeCd: '',
        remarks: '',
        hazardMachineFlag: 'Y',
        useFlag: 'Y',
        dailyFlag: 'Y',
      },
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      saveable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      hazardMachineItems: [
        { code: 'Y', codeName: '유해위험기계기구' },
        { code: 'N', codeName: '일반' },
      ],
      isSave: false,
      saveUrl: transactionConfig.mdm.equipment.class.insert.url,
      saveType: 'POST',
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;

      // api scope
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.detailUrl = selectConfig.mdm.equipment.class.get.url;
      this.insertUrl = transactionConfig.mdm.equipment.class.insert.url;
      this.updateUrl = transactionConfig.mdm.equipment.class.update.url;
      this.checkUrl = selectConfig.mdm.equipment.class.check.url;

      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getList() {
      this.reset();
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.saveable = false;
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
      },);
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.updateMode = true; 
      this.$http.url = this.$format(this.detailUrl, row.equipmentTypeCd, row.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.equipClassData = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addClass() {
      this.saveable = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.equipClassData = {
        plantCd: this.searchParam.plantCd,
        equipmentTypeName: '',
        equipmentTypeCd: '',
        upEquipmentTypeCd: this.equipClassData.equipmentTypeCd,
        remarks: '',
        hazardMachineFlag: 'Y',
        useFlag: 'Y',
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,
        equipmentTypeName: '',
        equipmentTypeCd: '',
        upEquipmentTypeCd: '',
        remarks: '',
        hazardMachineFlag: 'Y',
        useFlag: 'Y',
      };
    },
    saveClass() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크 
        if (_result) { 
          this.$http.url = this.$format(this.checkUrl, this.equipClassData.equipmentTypeCd, this.equipClassData.plantCd);
          this.$http.type = 'GET'; 
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: 
                '사업장 내 설비유형 코드가 이미 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ plantCd: result.data.plantCd, equipmentTypeCd: result.data.equipmentTypeCd })
    },
  }
};
</script>
